/**
 * Analytics class.
 *
 * @since unreleased
 */
export default class Analytics {
  /**
   * Analytics constructor.
   *
   * @since unreleased
   */
  constructor () {
    window.dataLayer = window.dataLayer || []
  }

  /**
   * Fire an analytics event.
   *
   * @since unreleased
   */
  event () {
    window.dataLayer.push(arguments)
  }
}
