/**
 * Navigation class.
 *
 * @since unreleased
 */
export default class Navigation {
  /**
   * Navigation constructor.
   *
   * @param {HTMLElement} navigation Navigation DOM element.
   * @since unreleased
   */
  constructor (navigation) {
    this.site = document.querySelector('.site')
    this.navigation = navigation
    this.menu = this.navigation.querySelector('.navigation__menu')
    this.items = this.menu.querySelectorAll('.navigation__item')

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const size = entry.contentBoxSize
          ? Array.isArray(entry.contentBoxSize)
            ? entry.contentBoxSize[0].inlineSize
            : entry.contentBoxSize.inlineSize
          : entry.contentRect.width
        if (size / 16 >= 36) {
          this.maybeRemoveNavigationMarkup()
        } else {
          this.maybeAddNavigationMarkup()
        }
      }
    })
    resizeObserver.observe(this.site)
  }

  /**
   * Maybe add navigation markup.
   *
   * @since unreleased
   */
  maybeAddNavigationMarkup () {
    // If there is already a navigation button, bail.
    if (this.navigation.querySelector('.navigation__button')) return

    const button = document.createElement('button')
    const icon = document.createElement('img')
    const text = document.createElement('span')

    this.navigation.parentElement.classList.add('navigation__container')

    // Set navigation state.
    this.navigation.setAttribute('aria-expanded', 'false')

    // Add navigation button.
    icon.setAttribute('src', '/img/icons/MdiMenu.svg')
    icon.setAttribute('alt', '')
    icon.setAttribute('width', '24')
    icon.setAttribute('height', '24')
    text.classList.add('visually-hidden')
    text.textContent = 'Open menu'
    button.classList.add('navigation__button')
    button.setAttribute('aria-controls', this.navigation.getAttribute('id'))
    button.appendChild(icon)
    button.appendChild(text)
    this.navigation.prepend(button)

    // Listen for button clicks.
    button.addEventListener('click', (event) =>
      this.toggleNavigationState(event),
    )

    // Hide navigation menu.
    this.menu.setAttribute('hidden', '')
  }

  /**
   * Maybe remove navigation markup.
   *
   * @since unreleased
   */
  maybeRemoveNavigationMarkup () {
    // If there is not already a navigation button, bail.
    const button = this.navigation.querySelector('.navigation__button')
    if (!button) return

    // Remove button.
    button.parentElement.removeChild(button)

    // Remove navigation expanded state.
    this.navigation.removeAttribute('aria-expanded')

    // Show navigation menu.
    this.menu.removeAttribute('hidden')
  }

  /**
   * Toggle navigation state.
   *
   * @param {Event} event The click event.
   * @since unreleased
   */
  toggleNavigationState (event) {
    const button = event.target.closest('button')
    const icon = button.querySelector('img')
    const text = button.querySelector('span')

    // Toggle navigation state.
    const expanded = this.navigation.getAttribute('aria-expanded') === 'true'
    this.navigation.setAttribute('aria-expanded', !expanded)

    // Toggle icon.
    icon.setAttribute(
      'src',
      expanded ? '/img/icons/MdiMenu.svg' : '/img/icons/MdiClose.svg',
    )

    // Toggle button text.
    text.textContent = expanded ? 'Open menu' : 'Close Menu'

    // Toggle menu state.
    if (expanded) {
      this.menu.setAttribute('hidden', '')
    } else {
      this.menu.removeAttribute('hidden')
    }
  }
}
