/**
 * Track class.
 *
 * @since unreleased
 */
export default class Track {
  /**
   * Track constructor.
   *
   * @param {HTMLElement} options.track    Track DOM element.
   * @param {HTMLElement} options.playlist Playlist DOM element.
   * @since unreleased
   */
  constructor ({ track, playlist }) {
    // If there is no track or playlist, bail.
    if (!track || !playlist) return

    // If there is no title or player, bail.
    this.track = track
    this.title = track.querySelector('[data-component="playlist-title"]')
    this.player = track.querySelector('[data-component="playlist-player"]')
    if (!this.track || !this.player) return

    // Get the playlist and tracks.
    this.playlist = playlist
    this.tracks = playlist.querySelectorAll('[data-component="playlist-track"]')

    // Listen for play events on the track and solo the track.
    this.player.addEventListener('play', event => this.solo(event))
  }

  /**
   * Solo the track.
   *
   * @param {event} event The play event.
   * @since unreleased
   */
  solo (event) {
    // Pause the other tracks.
    this.tracks.forEach(track => {
      if (track === this.track) return

      // Pause the track.
      track
        .querySelector('[data-component="playlist-player"]')
        .pause()
    })
  }
}
