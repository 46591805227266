import Track from './track'

/**
 * Playlist class.
 *
 * @since unreleased
 */
export default class Playlist {
  /**
   * Playlist constructor.
   *
   * @param {HTMLElement} playlist Playlist DOM element.
   * @since unreleased
   */
  constructor (playlist) {
    // If there is no playlist, bail.
    if (!playlist) return

    // If there are no tracks, bail.
    this.playlist = playlist
    this.tracks = playlist.querySelectorAll('[data-component="playlist-track"]')
    if (!this.tracks) return

    // Instantiate tracks.
    this.tracks.forEach(track => new Track({ track, playlist: this.playlist }))
  }
}
