import env from './env.js'
import Navigation from './components/navigation'
import Form from './components/form'
import Playlist from './components/playlist'
import Analytics from './components/analytics'
import { google } from '../../data/analytics.json'

const { BUILD_ENV, BUILD_VERSION } = env

/**
 * Initialize.
 *
 * @since unreleased
 */
function init () {
  // Initialize navigations.
  const navigations = document.querySelectorAll(
    '[data-component="navigation"]',
  )
  if (navigations) navigations.forEach((navigation) => new Navigation(navigation))

  // Initialize forms.
  const forms = document.querySelectorAll(
    '[data-component="form"]',
  )
  if (forms) forms.forEach((form) => new Form(form))

  // Initialize playlists.
  const playlists = document.querySelectorAll(
    '[data-component="playlist"]',
  )
  if (playlists) playlists.forEach((playlist) => new Playlist(playlist))

  if (BUILD_ENV === 'production') {
    // Initialize analytics.
    const analytics = new Analytics()

    // Fire a page load event.
    analytics.event('js', new Date())
    analytics.event('config', google.site_id)
  }

  console.info(`Environment: ${BUILD_ENV}`)
  console.info(`Version: ${BUILD_VERSION}`)
}

// Initialize.
init()
